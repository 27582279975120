/* Reset default styles */
*,
*::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*countdown styles*/
.counter
{
  display: flex; 
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}

/* Body styles */
body {
    font-family: 'Quicksand', sans-serif;
    color: #3f2e25;
    margin: 0;
    padding: 0;
    background-image: url('https://static.vecteezy.com/system/resources/previews/009/924/607/original/cute-brown-aesthetic-abstract-minimal-background-perfect-for-wallpaper-backdrop-postcard-background-vector.jpg');
    background-size: cover;
}

/* Container styles */
.landingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    position: relative;
}

/* App container styles */
.App {
    text-align: center;
    background-color: #FEFCF5;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 50px;
    max-width: 700px;
}

/* App logo styles */
.App-logo {

    height: 200px; /* Increased logo size */
    width: auto;
    display:block;
    margin-left:auto;
    margin-right: auto;
  
}

/* Register button styles */
.registerButton {
    background-color: #D39272;
    color: #FEFCF5;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
    padding: 15px 40px;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.registerButton:hover {
    background-color: #b87d5a;
}


/* Email input styles */
input[type="email"] {
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #D39272;
    background-color: #FEFCF5;
    color: #A2806C;
    outline: none;
    width: calc(100% - 32px); /* Adjusted width */
    max-width: 400px; /* Added max-width */
}